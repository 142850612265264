import React, {useRef, useState, useEffect} from 'react';
import Header from '../components/header/Header';
import './Home.css';
import moment from 'moment';
import {
    dateAtom,
    receiptTypeAtom,
    receiptTypeCntAtom,
    totalAmountAtom
} from '../state/headerAtom';

import { isModalOpenAtom } from '../state/modalAtom';
import { useRecoilState } from 'recoil';
import { receiptDataAtom, deleteAtom , confirmAtom} from '../state/HomeAtom';

import { receiptApi } from '../services/receiptService/receiptApi';
import { utilService } from '../util/utilService';
import { slideModalProps } from '../components/modal/slideModalProp';
import Loading from '../components/loading/Loading';
import SlideModal from '../components/modal/SlideModal';

import useModal from '../hook/useModal';
import useToggle from '../hook/useToggle';
import useNavigation from '../hook/useNavigation';

import PullToRefresh from 'react-simple-pull-to-refresh';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck, faCircleExclamation } from '@fortawesome/free-solid-svg-icons';
import { faTrashCan } from '@fortawesome/free-regular-svg-icons';

import useFetchResult from '../hook/useFetchResult';

const HomeRenewal = () => {
    const navigate = useNavigation();

    const pageNum = 1;
    const maxCount = 20;

    const { showModal, closeModal } = useModal();
    const { handleApiRst } = useFetchResult();

    //const [isConfirm, setIsConfirm] = useState();
    const [confirmYear, setConfirmYear] = useState();
    const [confirmMonth, setConfirmMonth] = useState();
    const [checkList, setCheckList] = useState([])

    const [isOpen, setIsOpen] = useRecoilState(isModalOpenAtom);

    const [date, setDate] = useRecoilState(dateAtom);
    const [receiptType, setReceiptType] = useRecoilState(receiptTypeAtom);
    const [receiptCnt, setReceiptCnt] = useRecoilState(receiptTypeCntAtom);
    const [totalAmount, setTotalAmount] = useRecoilState(totalAmountAtom); //eslint-disable-line no-unused-vars
    const [receiptData, setReceiptData] = useRecoilState(receiptDataAtom);

    // const [isDelete, setIsDelete] = useState(false);
    const [isDelete, setIsDelete] = useRecoilState(deleteAtom)
    const [isConfirm, setIsConfirm] = useRecoilState(confirmAtom)
    const [toggle, changeType] = useToggle();

    const [loading, setLoading] = useState(false);
    const deleteReciptId = useRef(0);

    const currentDate = new Date();
    const currentMonth = currentDate.getMonth() + 1; //월
    const currentDay = currentDate.getDate(); //일
    const rctxMonth = parseInt(date.split(' ')[1], 10); //헤더 월

    const addRctx = currentMonth === rctxMonth || currentDay >= 1 && currentDay <= 5; //해당월이거나 매월1일 ~ 5일
   

    const viewInit = () => {
        isOpen && setIsOpen(false);

        // test
        const userAgent = navigator.userAgent.toLowerCase();

        let os = "";
        let browser = "";

        const osList = [
            "Macintosh", "Windows", "Android", "iPhone", "iPad"
        ]

        os = osList.find((os) => userAgent.includes(os.toLowerCase())) || 'Other';
        
        const browsers = [
            'Chrome', 'Opera',
            'WebTV', 'Whale',
            'Beonex', 'Chimera',
            'NetPositive', 'Phoenix',
            'Firefox', 'Safari',
            'SkipStone', 'Netscape', 'Mozilla',
          ];

        
          if (userAgent.includes("edg")) {
            return "Edge";
          }
        
          if (userAgent.includes("trident") || userAgent.includes("msie")) {
            return "Internet Explorer";
          }

        browser = browsers.find((browser) => userAgent.includes(browser.toLowerCase())) || 'Other';

        console.log("os: " + os)
        console.log("browser: " + browser)

        const isNotPhone = (os !== "Android" || os !== "iPhone");
        const isNotLocal = window.location.host !== 'localhost:3000'

        // if(isNotPhone && isNotLocal) {
        //     window.opener = window.location.href;
        //     window.open('about:blank', '_self').close(); 
        //     window.close();
        // }
    }

    const goDetail = (receiptId) => {
        navigate.goPath('/detail/'+receiptId);
    }

    //확정하기
    const handleReceipt = async () => {
        if (receiptData) {
           
            setIsOpen(false);
            console.log("isConfirm", date.split(' ').length)
            if (!isConfirm) { //확정이 안되었을 때
                
                showModal({
                    modalType: 'CONFIRM',
                    modalProps: {
                        content: "총 "+receiptCnt+"건을 확정하시겠습니까?",
                        evt: confirmFunc
                    }
                }) 
                if(currentMonth < rctxMonth){
                    showModal(
                        {
                            modalType: 'ALERT',
                            modalProps: {
                                content: currentMonth+ "월 이전 데이터만 확정하기가 가능합니다."
                            }
                        }
                    )
                }
                if(date.split(' ').length > 2){
                    showModal(
                        {
                            modalType: 'ALERT',
                            modalProps: {
                                content: "일별 확정하기는 지원하지 않습니다."
                            }
                        }
                    )
                }
            } 
        } else {
            showModal(
                {
                    modalType: 'ALERT',
                    modalProps: {
                        content: '확정할 데이터가 존재하지 않습니다. :)',
                    }
                }
            )
            setIsOpen(false);
        }
    }
    
    const confirmFunc = async () => {
        let year = parseInt(date.split(' ')[0], 10);
        let month = parseInt(date.split(' ')[1], 10);

        let requestData = {
            year: year,
            month: month,
            data: {
                count: receiptCnt,
                memberUlid: '1',
                confirmType: receiptType
            }
        }
        console.log(requestData);
        console.log(localStorage.getItem('memberInfo'));
        await receiptApi.confirmReceipt(requestData)
        .then(res => {
            showModal(
                {
                    modalType: 'ALERT',
                    modalProps: {
                        content: '확정 완료되었습니다!',
                        evt: () => {
                            window.location.reload();
                            setIsConfirm(true);
                        }
                    }
                }
            )
        })
        .catch(error => {               
            // 에러 표시 필요 -> 200 이외도 고려 필요
            showModal(
                {
                    modalType: 'ERROR',
                    modalProps: {
                        content: error.response.data.message,
                    }
                }
            )
        })
    }

    const cancel = async () => {
        let year = parseInt(date.split(' ')[0], 10);
        let month = parseInt(date.split(' ')[1], 10);

        const requestData = {
            year: year,
            month: month,
            data: {
                memberUlid: "1",
                confirmType: receiptType
            }
        }

        await receiptApi.cancelConfirm(requestData)
        .then(res => {
            if (res.data.code === 200) {
                showModal(
                    {
                        modalType: 'ALERT',
                        modalProps: {
                            content: '취소가 완료되었습니다.',
                            evt: () => {
                                window.location.reload();
                            }
                        }
                    }
                )
            }
        })
        .catch(error => {
            showModal(
                {
                    modalType: 'ERROR',
                    modalProps: {
                        content: error.response.data.message,
                    }
                }
            )
        })
    }


    const cancelConfirm = () => {
        showModal(
            {
                modalType: 'CONFIRM',
                modalProps: {
                    content: '확정을 취소하시겠습니까?',
                    evt: cancel
                }
            }
        )
    }
    //삭제하기 클릭시 
    const handleDelete = async () => {
        //데이터가 있음
        if (receiptData){
            setIsDelete(true);
            if(isConfirm){
                setIsDelete(false);
                showModal({
                    modalType: 'ALERT',
                    modalProps: {
                        content: '확정된 데이터는 삭제할 수 없습니다. :)'
                    }
                }) 
            }
        } else { //데이터가 없을 때
            showModal(
                {
                    modalType: 'ALERT',
                    modalProps: {
                        content: '삭제할 데이터가 존재하지 않습니다. :)'
                    }
                }
            )
        }
    }
    const cancelDelete= () => {
        setIsDelete(false);

    }
    const onClickDelete = async (reciptId,date,paymentPlace,paymentAmount) => {
        deleteReciptId.current = reciptId;

        showModal(
            {
                modalType: 'CONFIRM',
                modalProps: {
                    content: date+' '+paymentPlace+' [ '+paymentAmount+' ] 원 \n 영수증을 삭제할까요?',
                    evt: deleteRecipt
                }
            }
        )
    };

    const deleteRecipt = async () => {
        await receiptApi.deleteReceipt(deleteReciptId.current)
        .then(res => {
            if(res.code === 200) {
                showModal(
                    {
                        modalType: 'ALERT',
                        modalProps: {
                            content: res.message+':)',
                            evt: () => {
                                window.location.reload();
                            }
                        }
                    }
                )
            }
        })
        .catch(error => {
            showModal(
                {
                    modalType: 'ERROR',
                    modalProps: {
                        content: error.response.data.message,
                    }
                }
            )
        })
    };

    useEffect(() => {
        const fetchData = async () => {
            let day;
            let groupData = [];

            let year = parseInt(date.split(' ')[0], 10);
            let month = parseInt(date.split(' ')[1], 10);

            if (date.split(' ').length > 2) {
                day = parseInt(date.split(' ')[2], 10);
            }

            // setLoading(true);

            try {
                const resCntObj = await receiptApi.getReceiptCount(year, receiptType);
                
                if (resCntObj.data.code === 200) {
                    let monthCntList = resCntObj.data.data.lists;

                    let cntObj = (monthCntList).find((element) => month === element.month);
                    
                    let dividePage= cntObj.counts / maxCount;

                    if (cntObj.counts % maxCount === 0) {
                        dividePage = dividePage - 1;
                    }

                    let maxPage = Math.floor(pageNum + dividePage);

                    let updatedGroupData = { ...groupData }

                    for (let i=pageNum; i<=maxPage; i++) {
                        const requestData = {
                            year: year,
                            month: month,
                            receiptType: receiptType,
                            pageNum: i
                        }

                        const response = await receiptApi.getReceiptList(requestData);

                        if (response.status === 200) {
                            let resData = response.data.data.lists;

                            setConfirmYear(year);
                            setConfirmMonth(month);
                            setIsConfirm(response.data.data.confirmed);

                            if (typeof day === "undefined") {
                                (resData).forEach(item => {
                                    const date = utilService.formatDate(item.date, 'yyyy.MM.dd');
                                    
                                    if (!updatedGroupData[date]) {
                                        updatedGroupData[date] = [];
                                    }
                                    item.paymentAmount = (item.paymentAmount).toLocaleString();

                                    item.date = utilService.formatDate(item.date, 'MM.dd');
                                    updatedGroupData[date].push(item);
                                });
                                
                            } else {                           
                                let selectDate = moment(date, 'YYYY[년] MM[월] DD[일]').format('yyyy.MM.DD');
    
                                (resData).forEach(item => {
                                    const date = utilService.formatDate(item.date, 'yyyy.MM.dd');
                    
                                    if (selectDate === date) {
                    
                                        if (!updatedGroupData[selectDate]) {
                                            updatedGroupData[selectDate] = [];
                                        }
                                        item.paymentAmount = (item.paymentAmount).toLocaleString();
                    
                                        item.date = utilService.formatDate(item.date, 'MM.dd');
                                        updatedGroupData[selectDate].push(item);
                                    }
                                });
                            }
                        }
                    }

                    setReceiptData(Object.keys(updatedGroupData).length > 0 ? updatedGroupData : null);
                        setReceiptCnt(Object.keys(updatedGroupData).length > 0 ? () => {
                            let count = 0;

                            (Object.keys(updatedGroupData)).map((key) => {
                                count = updatedGroupData[key].length + count 
                            })

                            return count;
                        } : 0)
                        setTotalAmount(Object.keys(updatedGroupData).length > 0 ? () => {
                            let amount = 0;

                            (Object.keys(updatedGroupData)).map((key) => {
                                (updatedGroupData[key]).map((element) => {
                                    amount = parseInt((element.paymentAmount).replace(/,/g, "")) + parseInt(amount);
                                })
                            })

                            return amount.toLocaleString();
                        } : 0)
                }

                setLoading(false);
            } catch (err) {
                showModal({
                    modalType: 'ERROR',
                    modalProps: {
                        content: err.response.data.message,
                        evt: () => {
                            receiptType === 'corporation' && changeType(0)
                            closeModal();
                        }
                    }
                });
            }
        }

        viewInit();

        fetchData();
    }, [receiptType, date])
    
    

    const fetchData = () => {
        return new Promise(resolve => {
          setTimeout(() => {
            setIsDelete(false);
            setDate(moment().format('YYYY년 M월'));
            setReceiptType('individual');
            
            resolve();
          }, 1000);
        });
      };
    
    const handleRefresh = () => {
    return fetchData();
    };
    const handleHeader = () =>{
        setIsDelete(false);
    }
    return (
        <div className=
        {` ${(isConfirm || isDelete) ? 'detail-wrap padding: 0 0 125px 0' : 'detail-wrap type2'}`} 
         >
            <div onClick={handleHeader}>
                <Header />
            </div>
            {
                loading &&
                <Loading />
            }

        <PullToRefresh onRefresh={handleRefresh}> 
            <div className=
            {` ${(isConfirm || isDelete) ? 'detail-bg type2' :'detail-bg' }`} 
            >
               
                {
                    receiptData ?
                    Object.keys(receiptData).map((date, dateIndex) => (
                        <div key={dateIndex}>
                            <div
                                className='content-date'
                            >
                                <span>{date}</span>
                            </div>
                            <ul>
                                {
                                    receiptData[date].map((item, index) => (
                                        
                                        <li
                                            className='content-li-list'
                                            key={index}
                                            
                                        >
                                            <span className='content-li-date' style={{display: 'flex'}}>
                                                {item.date}
                                            </span>

                                            <div 
                                                className='content-li-summary'
                                                onClick={() => goDetail(`${item.reciptId}`)}
                                            >
                                                <p>{item.paymentPlace}</p>
                                                <p className='content-li-place'>{item.summary}</p>
                                            </div>
                                            
                                            <span
                                                className={`${isConfirm ? 'content-li-amount-confirm' : 'content-li-amount'}`}
                                                onClick={() => goDetail(`${item.reciptId}`)}
                                            >
                                                {item.paymentAmount}원
                                            </span>
                                            {/* 삭제하기 아이콘 */}
                                            {isDelete &&
                                                <div className='pr-3'>
                                                    <button
                                                        onClick={() => onClickDelete(`${item.reciptId}`
                                                        ,`${item.date}`
                                                        ,`${item.paymentPlace}`
                                                        ,`${item.paymentAmount}`)}
                                                    >
                                                         <p className="text-main text-xl"> <FontAwesomeIcon icon={faTrashCan} /></p>
                                                    </button>
                                                </div>
                                            }
                                           
                                        </li>
                                    ))
                                }   
                            </ul>
                        </div>
                    )) : <p className='none-wrap'>등록된 영수증이 없습니다</p>
                }
              
            </div>

            {/* 확정하기 */}
            {(receiptData &&  isConfirm )&&
                <div className='isconfirm_warp'>
                    <div>
                        <p className="text-main"> <FontAwesomeIcon icon={faCircleExclamation} /></p>
                        {!addRctx ?
                            <p>{rctxMonth}월은 확정되어 수정이 불가합니다.</p>
                            :
                            <p style={{fontSize:"14px", marginTop:"1%"}}> {rctxMonth}월은 확정취소 후 등록/수정이 가능합니다.</p> 

                        }
                     
                    </div>  
                   
                    <button 
                        className={`${addRctx ? 'button' : 'button type2'}`}
                        style={{margin:"5% 0%"}}
                        onClick={() => cancelConfirm()}
                        disabled={addRctx ? false : true}
                    >
                    확정취소
                    </button>
                </div>
            }
            {/* 팝업오픈 */}
            { isOpen &&
                <div className='category-btn-wrap'>
                    <SlideModal
                        open={isOpen} 
                        element={toggle === "individual" ? slideModalProps.home : slideModalProps.corporation_home }
                        confirm={handleReceipt}
                        delete={handleDelete}
                    />
                </div>
            }
            {/* 삭제하기 */}
            {(receiptData && isDelete)  && 
                <div className='isconfirm_warp'>
                    <div>
                        <p className="text-main"> <FontAwesomeIcon icon={faCircleExclamation} /></p>
                        <p>삭제된 영수증은 복구할 수 없습니다.</p>
                    </div>
                    <button 
                        className="button" 
                        style={{margin:"5% 0%"}}
                        onClick={() => cancelDelete()}
                    >
                    삭제취소
                    </button>
                </div>
            }
             </PullToRefresh>
        </div>
    )
}

export default HomeRenewal;