import React, { useState, useEffect } from 'react';

import Header from '../../components/header/Header';

import { headerTitleProps } from '../../components/header/headerProps';

import { isModalOpenAtom } from '../../state/modalAtom';
import { useRecoilState } from 'recoil';

import receiptApi from '../../services/receiptService/receiptApi';
import { utilService } from '../../util/utilService';
import useNavigation from '../../hook/useNavigation';

import { useLocation } from 'react-router-dom';

import { receiptTypeAtom } from '../../state/headerAtom';

import useModal from '../../hook/useModal';

import { createBrowserHistory } from 'history';
export const history = createBrowserHistory();

//import { history } from 'history';

const Write = () => {
    const { showModal } = useModal();
    const { closeModal } = useModal();

    const [isOpen, setIsOpen] = useRecoilState(isModalOpenAtom);
    const [isSubmit, setIsSubmit] = useState(false);
    const [reqData, setReqData] = useState();

    const receiptType = useRecoilState(receiptTypeAtom)[0];

    const [validCheckData, setValidCheckData] = useState();
    const [enteredNum, setEnteredNum] = useState('');

    const handleInputChange = (e) => {
        let { name, value } = e.target;

        if(e.target.name === 'paymentAmount'){
            let price = e.target.value;
            value = Number(price.replaceAll(',', ''));
            price = Number(price.replaceAll(',', ''));

            if(!isNaN(price)){
                price = price.toLocaleString('ko-KR');
                setEnteredNum(price);
//                console.log('---1 : ' + e.target.name + ' : ' + value + ' : ' + price);
            }
        }

        setReqData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    }

    const navigate = useNavigation();

    const goHome = () => {
        navigate.goPath('/');
    }

    const selectOption = [
        '식대',
        '회식비',
        '접대비',
        '교통비',
        '비품구매',
        '간식비',
        '차량유지비',
        '기타'
    ]

    const writeData = [
        {id : 1, title: "지불처"},
        {id : 2, title: "날짜"},
        {id : 3, title: "적요"},
        {id : 4, title: "금액"},
        {id : 5, title: "대상자"},
        {id : 6, title: "비고"}
    ];

    const write = async () => {
        receiptApi.writeReceipt(reqData)
        .then(res => {
            if(res.status === 200){
                showModal(
                    {
                        modalType: 'ALERT',
                        modalProps: {
                            content: res.data.message+':)',
                            evt: () => {
                                closeModal();
                                goHome();
                            }
                        }
                    }
                )
            }
        }).catch(error => {
            showModal(
                {
                    modalType: 'ERROR',
                    modalProps: {
                        content: error.response.data.message,
                    }
                }
            )
        })
    }


const onClickWrite = () => {
    
    const checkParam = {
        paymentPlace: '지불처',
        date: '날짜',
        summary: '적요',
        paymentAmount: '금액',
    }

    const validationResult = utilService.validCheck(reqData, checkParam);

    if (typeof validationResult === 'boolean') {
        setValidCheckData(null);

        showModal(
            {
                modalType: 'CONFIRM',
                modalProps: {
                    content: '영수증을 등록합니다.',
                    evt: write
                }
            }
        )
        
    } else {
        setValidCheckData(validationResult)
    }
};

const viewInit = () => {
    isOpen && setIsOpen(false);
}

const preventGoBack = () => {
    // eslint-disable-next-line no-restricted-globals
    window.history.pushState(null, '', location.href);
};

//const [isListExpanded, setIsListExpanded] = useState(false);
//const toggleListExpanded = () => {
//    setIsListExpanded((expanded => !expanded));
//};

useEffect(() => {
//        viewInit();

        setReqData({
            attendees: "",
            comment: "",
            confirmType: receiptType,
            date: "",
            paymentAmount: "",
            paymentPlace: "",
            summary: ""
        })

//        const listenBackEvent = () => {
//            // 뒤로가기 할 때 수행할 동작을 적는다.
//            alert('test');
//        };
//
//        console.log('useEffect 호 출');
//
//        const unlistenHisotryEvent = history.listen(({ action }) => {
//
//            console.log('action : ' + action);
//
//            if(action === 'POP') {
//                listenBackEvent();
//            }
//        });
//
//        return unlistenHisotryEvent;

        // eslint-disable-next-line no-restricted-globals
//        window.history.pushState(null, '', location.href);
//        window.addEventListener('popstate', preventGoBack);
//
//        return () => {
//            // eslint-disable-next-line no-restricted-globals
//            window.removeEventListener('popstate', preventGoBack);
//        }

    }, []);

    return (
            //<form id="myForm" onSubmit={onSubmitHandler}>
        <div className='detail-wrap'>
            <div>
                <Header title={headerTitleProps.write} />
            </div>

            <div className='detail-bg' style={{display:'grid', gap:'3%'}}>
                {
                writeData.map((data, index, writeData) => (
                    <div key={index} >
                        <p className='detail-title' style={{ backgroundColor: "revert" }}> {data.title} </p>

                        {
                            index === 0 ?
                            <div className='input-text'>
                                <input
                                    onChange={handleInputChange}
                                    defaultValue=''
                                    name="paymentPlace"
                                    placeholder="지불처를 기입하세요."
                                />
                            </div>
                            :
                            index === 1 ?
                            <div className='input-text'>
                                <input
                                    className='calendar'
                                    onChange={handleInputChange}
                                    name="date"
                                    type="date"
                                />
                            </div>
                            :
                            index === 2 ?
                            <div className='input-text'>
                                <select
                                    name="summary"
                                    onChange={handleInputChange}
                                >
                                    <option value="default" disabled style={{ color: "#ccc" }} selected>
                                        ---선택---
                                    </option>
                                    {
                                        selectOption.map((option, index) => (
                                            <option
                                              value={option}
                                              key={index}
                                            >
                                              {option}
                                            </option>
                                        ))
                                    }
                                </select>
                            </div>
                            :
                            index === 3 ?
                            <div className='input-text'>
                                <input
                                    type="text"
                                    onChange={handleInputChange}
                                    value={enteredNum}
                                    name="paymentAmount"
                                    placeholder="금액을 기입하세요."
                                />
                            </div>
                            :
                            index === 4 ?
                            <div className='input-text'>
                                <input
                                    defaultValue=''
                                    onChange={handleInputChange}
                                    name="attendees"
                                    placeholder="대상자를 기입하세요."
                                />
                            </div>
                            :
                            index === 5 ?
                            <div className='detail-txt-area-wrap'>
                                <textarea
                                    onChange={handleInputChange}
                                    name="comment"
                                    rows="3"
                                    cols="50"
                                    className='detail-txt-area'
                                />
                            </div>
                            :
                            <div></div>
                        }

                    </div>
                ))
                }

            </div>
            <div className='isconfirm_warp'>
                <div>
                    <p style={{ color:"rgb(93, 176, 117)"}}> {validCheckData}</p>
                </div>
                <button
                    className="button"
                    style={{margin:"5% 0%"}}
                    onClick={() => onClickWrite()}
                >
                등록하기
                </button>
            </div>

            <div>
            </div>
        </div>
    )
}

export default Write;