import { format } from 'date-fns';

export const utilService = {
    formatDate : (date, dateFormat) => {
        let formatDate;
        if (date !== null ||
                date !== '') {
            formatDate = format(new Date(date), dateFormat);
        }

        return formatDate;
    },

    validCheck : (model, checkParam) => {
        let result = true;

        if (model && checkParam) {
            let modelKeyArr = Object.keys(model);

            let paramKeyArr = Object.keys(checkParam);

            for (let i = 0; i < paramKeyArr.length; i++) {
                for (let j = 0; j < modelKeyArr.length; j++) {
                    if (paramKeyArr[i] === modelKeyArr[j]) {
                        if (model[paramKeyArr[i]] === '' || model[paramKeyArr[i]] === null) {
                            result = checkParam[paramKeyArr[i]] + "를(을) 입력하세요";

                            return result;
                        } else {
                            if (paramKeyArr[i] === 'email') {
                                const emailRegExp = /^([a-z0-9_\.-]+)@([\da-z\.-]+)\.([a-z\.]{2,6})$/;

                                if (!emailRegExp.test(model[paramKeyArr[i]])) {
                                    result = "이메일 형식을 확인하세요";

                                    return result;
                                }
                            }
                        }
                    }
                }
            }

            // for (let paramKey of paramKeyArr) {
            //     for (let modelKey of modelKeyArr) {
            //         if (paramKey === modelKey) {
            //             if (model[paramKey] === '' || model[paramKey] === null) {
            //                 result = checkParam[paramKey] + "를 입력하세요";

            //                 return result;
            //             } else {
            //                 if (paramKey === 'email') {
            //                     const emailRegExp = /^([a-z0-9_\.-]+)@([\da-z\.-]+)\.([a-z\.]{2,6})$/;
                            
            //                     if (!emailRegExp.test(model[paramKey])) {
            //                         result = "이메일 형식을 확인하세요";

            //                         return result
            //                     }
            //                 } 
            //             }
            //         }
            //     }
            // }

            return result;
        }
    }
}